.menu-level-1 {
    text-align: right;
    font-family: $light-ff;
    font-size: 1.4rem;
    padding-right: 5%;
    ul {
      list-style: none;
      margin: 0;
      margin: 0;
    }
    li {
      display: inline-block;
      white-space: nowrap;
      position: relative;
    }
    li.active {
      &:after {
        content: "";
        border-left: .5rem solid transparent;
        border-right: .5rem solid transparent;
        border-bottom: .5rem solid $dark;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      a {
        color: lighten($dark, 5%);
        cursor: default;
      }
    }
    a {
      cursor: pointer;
      white-space: nowrap;
      text-decoration: none;
      display: block;
      padding: 0.7rem;
      color: $gray-light;
      &:hover {
        color: lighten($dark, 5%);
      }
    }
}

.menu-level-2 {
    font-size: 1.7rem;
    text-align: right;
    letter-spacing: .1rem;
    font-family: $reg-ff;
    text-transform: uppercase;
    background: $dark;
    width: 100%;
    margin: 0;
    z-index: 10;
    padding-right: 5%;
    ul {
        list-style: none;
        margin: 0;
        margin: 0;
    }
    li {
        display: inline-block;
        white-space: nowrap;
    }
    a {
      cursor: pointer;
      white-space: nowrap;
      text-decoration: none;
      display: block;
      padding: 1.4rem 1.6rem;
      color: $light;
    }
    a:hover {
        background: darken($dark, 10%);
        color: $light;
    }
    .darkbar-li-desk {
        display: inline-block;
    }
    .darkbar-li-tab {
        display: none;
    }
}

.menu-level-3 {
    font-size: 1.5rem;
    text-align: right;
    background: $light;
    font-family: $reg-ff;
    width: 100%;
    margin: 0;
    z-index: 1;
    border-bottom: .3rem solid $dark;
    padding-right: 5%;
    ul {
        list-style: none;
        margin: 0;
        margin: 0;
    }
    > ul > li {
        display: inline-block;
        white-space: nowrap;
        position: relative;
    }
    ul.dropdown {
      position: absolute;
      top: 4rem;
      border-top: .3rem $dark solid;
      display: block;
      z-index: 999;
      background: rgba(white, .9);
      right: 0;
      display: none;
      a {
        font-size: 1.4rem;
        padding: .8rem 1.5rem;
      }
    }
    a {
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
        display: block;
        color: $dark;
        padding: 1.1rem 1.5rem;
    }
    a:hover {
        background-color: #fff;
        color: $orange;
    }
    .darkbar-li-desk {
        display: inline-block;
    }
    .darkbar-li-tab {
        display: none;
    }
}

.logo {
    position: absolute;
    left: 5%;
    top: 0;
    height: auto;
    width: 130px;
    z-index: 11;
}

.logo_bar {
    display: none;
}
