@media screen and (min-width: 2108px) {

.div_search {
	display: inline-block;
	margin-right: 25px;
	}
}

@media screen and (min-width: 1921px) {

html {
	font-size: 85%;
}

.home_text {
	padding: 150px 10% 175px 10%;

	h1 {
		margin: 10px 0;
	}

	img {
		width: 100px;
		height: 100px;
	}
}

.home_gif, .home_prod {
	padding: 0 2.5%;
}

.home-cont {
	h1 {
		padding: 0 2.5%;
	}
}

.home-brands {
	padding: 0 2%;
	 img {
		 padding: 0 3%;
	 }
}


.logo {
	width: 170px;
}

.darktopbar {

	a {
		padding: 30px 40px 25px 40px;
	}

}

.lighttopbar {
	padding: 7px;
}

.prod {
	padding: 10px 2%;
}

.prodpage_images, .prodpage_text  {
	margin-top: 100px;
}

.contact-request {
	height:55%;
}

.contact-button-cta {
	height: 50px;
}

.button-outline-width {
	min-width: 500px;
}

.aboutus-image-cont {
	height: 300px;
}

.gc-prod-cont {
	padding: 2%;
}

}
