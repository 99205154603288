@keyframes invalid-css {
    0% { transform: translate(0px, 0); }
    12.5% { transform: translate(-10px, 0); }
    25% { transform: translate(0, 0); }
    37.5% { transform: translate(10px, 0); }
    50% { transform: translate(0, 0); }
    62.5% { transform: translate(-8px, 0); }
    75% { transform: translate(0, 0); }
    87.5% { transform: translate(8px, 0); }
    100% { transform: translate(0, 0); }
}

