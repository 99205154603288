/* mixitup */
.main-cont {
  .fail-message {
    display: none;
  }
}

.main-cont.fail,
.main-cont.mixitup-container-failed {
  .fail-message {
    display: block;
    margin-bottom: 4em;
  }
  .fail-top {
    margin-top: 2em;
    margin-bottom: 2em;
    text-align:center;
    h3 {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}

.div_search {
  display: block;
}

/* dropdowns */

#dropdown {
  font-size: 2rem;
  width: 88%;
  padding: 0;
  margin: 20px auto auto auto;
  text-align: center;
  input[type=text] {
    min-width: 195px;
    min-width: 24rem;
    font-size: 1.8rem;
    font-family: $reg-ff;
    line-height: 1.2;
    color: $dark;
    outline: none;
    padding: 10px;
    margin: 5px 1px 5px 0px;
    border: 1px solid #DDDDDD;
    display: block;
  }
  input[type=text]:focus {
    border: 1px solid $orange;
  }
  .button-white {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.dropdown-wrap {
  font-size: 1.6rem;
  background: $white;
  text-align: left;
  margin: 0px 4px 0 0;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;
  color: $dark;
  border-bottom: 2px solid #c9c9c9;
  span {
    padding: 10px 35px 10px 20px;
    display: block;
  }
}

.dropdown-arrow:after {
  content: "\f078";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-size: 1.5rem;
  position: absolute;
  right: 10px;
  top: 29%;
}

.dropdown-x {
  color: $orange;
}

.dropdown-x:after {
  content: "\f00d";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-size: 1.5rem;
  position: absolute;
  right: 10px;
  top: 29%;
  color: $orange;
}

.dropdown-wrap .dropdown {
  position: absolute;
  top: 140%;
  left: 0;
  right: 0;
  background: $white;
  list-style: none;
  z-index: 100;
  @include transition(all, 0.2s, ease-in);
  border-bottom: 1px solid #c9c9c9;
  /* Hiding */
  opacity: 0;
  pointer-events: none;
}

.dropdown-wrap .dropdown:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 15px;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: $white transparent;
}

.dropdown-wrap .dropdown:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 13px;
  border-bottom: 1px solid #c9c9c9;
}

.dropdown-wrap .dropdown li a {
  display: block;
  padding: 12px;
  line-height: 1.3;
  text-decoration: none;
  color: $dark;
  border-bottom: 1px solid #c9c9c9;
  @include transition(all, 0.2s, ease-out);
}

.dropdown-wrap .dropdown li i {
  float: right;
  color: inherit;
}

.dropdown-wrap .dropdown li:first-of-type a {
  outline: 0;
}

.dropdown-wrap .dropdown li:last-of-type a {
  border: none;
  outline: 0;
}


/* dropdowns - hover state */

.dropdown-wrap:hover,
#viewall:hover {
  color: $orange;
  background-color: $white;
  outline: 0;
  border-bottom: 0px solid #c9c9c9;
  margin-bottom: 1px;
}

.dropdown-wrap .dropdown li:hover a {
  background: $orange;
  color: $white;
  outline: 0;
}


/* dropdowns - active f */
.dropdown-wrap.active .dropdown {
  opacity: 1;
  pointer-events: auto;
}


/* No CSS3 support */
.no-opacity .dropdown-wrap .dropdown,
.no-pointerevents .dropdown-wrap .dropdown {
  display: none;
  opacity: 1;
  pointer-events: auto;
}

.no-opacity .dropdown-wrap.active .dropdown,
.no-pointerevents .dropdown-wrap.active .dropdown {
  display: block;
}


.wrapper-dropdown-filters {
  width: 21rem;
}

#dropdown input.filter-search {
  padding: 10px 0 10px 3px;
  min-width: 23rem;
}


fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
#dropdown fieldset {
  display: inline-block;
}
#dropdown select{
  -webkit-appearance: none;
  font: inherit;
  font-family: inherit;
  display: inline-block;
  height: 42px;
  padding: 8px 12px;
  line-height: 1.42857143;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid white;
  border-radius: 0;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
  transition: all ease-in-out 200ms;
  font-size: 1.8rem;
  background-color: white;
  text-align: left;
  padding: 5px 40px 5px 10px;
  margin: 5px 1px 5px 0;
  color: $dark;
  border-bottom: 2px solid #c9c9c9;
  background-image: url('../img/arrow-down.svg');
  background-size: 1.8rem;
  background-position: 95% center;
  background-repeat: no-repeat;
}

.product-template #dropdown {
  fieldset { display: inline-block; }
  br       { display: none;         }
}
