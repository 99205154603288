@font-face {
  font-family: 'amp';
  src: url('../font/amp.eot');
  src: url('../font/amp.eot?#iefix') format('embedded-opentype'),
       url('../font/amp.woff') format('woff'),
       url('../font/amp.ttf')  format('truetype'),
       url('../font/amp.svg#77dda217d4dc739057f28730197d4570') format('svg');

  font-style:   normal;
  font-weight:  normal;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../font/fontawesome-webfont.eot?v=4.6.1');
  src: url('../font/fontawesome-webfont.eot?#iefix&v=4.6.1') format('embedded-opentype'),
    url('../font/fontawesome-webfont.woff2?v=4.6.1') format('woff2'),
    url('../font/fontawesome-webfont.woff?v=4.6.1') format('woff'),
    url('../font/fontawesome-webfont.ttf?v=4.6.1') format('truetype'),
    url('../font/fontawesome-webfont.svg?v=4.6.1#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'prox-light';
  src: url('../font/p-light.eot');
  src: url('../font/p-light.eot?#iefix') format('embedded-opentype'),
       url('../font/p-light.woff') format('woff'),
       url('../font/p-light.ttf')  format('truetype'),
       url('../font/p-light.svg#da6ddaf3cf69afbba47bd74b5010b98d') format('svg');

  font-style:   normal;
  font-weight:  normal;
}

@font-face {
  font-family: 'prox-light-ital';
  src: url('../font/p-light-ital.eot');
  src: url('../font/p-light-ital.eot?#iefix') format('embedded-opentype'),
       url('../font/p-light-ital.woff') format('woff'),
       url('../font/p-light-ital.ttf')  format('truetype'),
       url('../font/p-light-ital.svg#1a04ccc617b24808e8115891ca97cce8') format('svg');

  font-style:   normal;
  font-weight:  normal;
}


@font-face {
  font-family: 'prox-reg';
  src: url('../font/p-reg.eot');
  src: url('../font/p-reg.eot?#iefix') format('embedded-opentype'),
       url('../font/p-reg.woff') format('woff'),
       url('../font/p-reg.ttf')  format('truetype'),
       url('../font/p-reg.svg#2c1459d58560f08b4b0586fbe80aa4cd') format('svg');

  font-style:   normal;
  font-weight:  normal;
}

@font-face {
  font-family: 'prox-reg-ital';
  src: url('../font/p-reg-ital.eot');
  src: url('../font/p-reg-ital.eot?#iefix') format('embedded-opentype'),
       url('../font/p-reg-ital.woff') format('woff'),
       url('../font/p-reg-ital.ttf')  format('truetype'),
       url('../font/p-reg-ital.svg#77f1c682604b6b0472d490a8e6c22d18') format('svg');

  font-style:   normal;
  font-weight:  normal;
}

@font-face {
  font-family: 'prox-semi';
  src: url('../font/p-semibold.eot');
  src: url('../font/p-semibold.eot?#iefix') format('embedded-opentype'),
       url('../font/p-semibold.woff') format('woff'),
       url('../font/p-semibold.ttf')  format('truetype'),
       url('../font/p-semibold.svg#67a6c9f6d7dc8f35ecb08de766ea56f0') format('svg');

  font-style:   normal;
  font-weight:  normal;
}

@font-face {
  font-family: 'prox-semi-ital';
  src: url('../font/p-semibold-ital.eot');
  src: url('../font/p-semibold-ital.eot?#iefix') format('embedded-opentype'),
       url('../font/p-semibold-ital.woff') format('woff'),
       url('../font/p-semibold-ital.ttf')  format('truetype'),
       url('../font/p-semibold-ital.svg#507561f3ade737ab57270dba6021e34c') format('svg');

  font-style:   normal;
  font-weight:  normal;
}

@font-face {
  font-family: 'prox-bold';
  src: url('../font/p-bold.eot');
  src: url('../font/p-bold.eot?#iefix') format('embedded-opentype'),
       url('../font/p-bold.woff') format('woff'),
       url('../font/p-bold.ttf')  format('truetype'),
       url('../font/p-bold.svg#d1e00a8fb1fd9b428ab6b90409479fde') format('svg');

  font-style:   normal;
  font-weight:  normal;
}

@font-face {
   font-family: 'StateFaceRegular';
   src: url('../font/stateface-regular-webfont.eot');
   src: url('../font/stateface-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../font/stateface-regular-webfont.woff') format('woff'),
        url('../font/stateface-regular-webfont.ttf') format('truetype'),
        url('../font/stateface-regular-webfont.svg#StateFaceRegular') format('svg');
   font-weight: normal;
   font-style: normal;
}

.stateface {
  font-family: 'StateFaceRegular'!important;
}

.flaticon-arrow {
  font-family: 'Flaticon', sans-serif;
}
