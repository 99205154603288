@media screen and (max-width: 1023px) {
  .main-cont {
    width: 100%;
    padding: 25px 2% 0 2%;
  }
  .vid_cont {
    padding: 0 5% 0 5%;
    margin: 50px auto 50px auto;
  }
  .gc-cont {
    padding: 30px 2% 0 2%;
  }
  .gc_prod,
  .gc-prod-cont {
    width: 49%;
    padding: 30px 2% 0 2%;
  }
  /* _tablet top navigation */
  .logo_bar {
    display: block;
    width: 100%;
    text-align: center;
    background-color: #f54828;
    z-index: 10;
    height: 55px;
  }
  .logo_mobile {
    width: 130px;
    height: auto;
    padding-top: 8px;
    padding-bottom: 5px;
    z-index: 11;
  }
  .menu-level-1 {
    text-align: center;
    padding: 0;
    margin: 0;
    a {
      padding: 5px 10px;
    }
  }
  .menu-level-2 {
    text-align: center;
    margin-bottom: 0px;
    padding-right: 0;
    letter-spacing: 0px;
    ul {
      padding: 0;
      margin: 0;
    }
    .darkbar-li-desk {
      display: none;
    }
    .darkbar-li-tab {
      display: inline-block;
    }
  }
  .menu-level-3 {
    text-align: center;
    margin-bottom: 0px;
    padding-right: 0;
    letter-spacing: 0px;
    ul {
      padding: 0;
      margin: 0;
    }
    .darkbar-li-desk {
      display: none;
    }
    .darkbar-li-tab {
      display: inline-block;
    }
  }
  .home_text2 {
    padding-top: 200px
  }
  .home_text3 {
    padding-top: 35px
  }
  .logo {
    display: none;
  }
  .button-outline-width {
    width: 60%;
  }
  .home_gif {
    max-width: 49%;
    height: auto;
    padding: 0 3% 0 3%;
  }
  .home_prod {
    max-width: 49%;
    height: auto;
    padding: 0 3% 0 3%;
    margin: 10px 0 0 0;
  }
  .home-cont {
    padding-top: 0;
    padding-bottom: 50px;
    h1 {
      padding: 0 3%;
    }
  }
  .home-brands img {
    vertical-align: middle;
    max-width: 25%;
    padding: 2%;
    height: auto;
  }
  /* _tablet - PRODUCT PAGE AND LAYOUTS */
  .prodpage_images,
  .prodpage_text {
    margin-top: 0;
  }
  .prod {
    width: 49%;
    padding: 0 2% 0 2%;
    margin: 20px 0 0 0;
  }
  /* _tablet - DROPDOWNS */
  #dropdown {
    width: 100%;
    padding: 0 2% 0 2%;
  }
  #viewall {
    width: 10%;
    min-width: 110px;
    padding: 10px;
    margin: 0px 5px 0px 10px;
  }
  /* _tablet VIDEO PAGE */
  .button-white-cont {
    width: 100%;
  }
  .button-white {
    padding: 10px 15px 10px 15px;
    margin: 20px 10px 0 0;
  }
  /* _tablet PRODUCT PAGE */
  .prodpage_images {
    padding: 10px 3% 0 3%;
  }
  .prodpage_text {
    padding: 5px 3% 10px 3%;
  }
  /* _tablet ABOUT PAGE */
  .aboutus-image-cont {
    height: 200px;
  }
  .about-drop-cont {
    width: 75%;
    margin: -50px auto 0 auto;
  }
  .aboutus-nav {
    width: 100%;
    padding: 0;
    text-align: center;
    h1 {
      display: none;
    }
    h3 {
      color: $dark;
    }
    li:hover h3 {
      color: $white;
    }
    .dropdown-wrap {
      display: block;
    }
    .parent {
      margin: 0;
    }
    .nested {
      font-size: 18px;
      font-size: 1.8rem;
      margin: 0 0 0 20px;
    }
    .nested:before {
      content: "+";
      display: inline-block;
    }
  }
  .aboutus-content {
    width: 100%;
    margin: 0;
    padding: 0 5%;
  }
  /* _tablet CONTACT */
  .contact {
    width: 90%;
    height: 90%;
  }
  .contact-request,
  .contact-info {
    width: 80%;
  }
  /* _tablet remove transitions & animations to increase speed */
  h1 a,
  h2 a,
  h3 a,
  h5 a,
  p a,
  .menu-level-1 a,
  .menu-level-2 a,
  .prod_thumb,
  .button-cta,
  .dropdown-wrap .dropdown,
  .dropdown-wrap .dropdown li a,
  .prodpage_text,
  .prodpage_tag,
  .button-cta,
  #closeContact,
  .contact-request form,
  .contact-float label {
    @include transition(0, ease-in-out, all);
  }
}
