.home_text { // 404 page
  width: 100%;
  padding: 60px 10%;
  text-align: center;
  background-color: white;
}
.home_text_gc {
  width: 100%;
  height: 400px;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #959595;
}

#amp {
  font-family: $amp;
}

.rotate,
.your,
.rotate2,
.rotate3 {
  display: inline-block;
}
.inner {
  padding: 0 1.8%;
}
.home-cont {
  padding-top: 0;
  padding-bottom: 50px;
  margin: 25px 0;
  border-bottom: 1px solid #959595;
  h1 {
    padding: 0 2%;
  }
  .what-we-do {
    padding: 0 2%;
    font-family: $reg-ff;
    font-size: 2rem;
    margin-bottom: 3rem;
    * {
      font-family: $reg-ff;
      font-size: 2rem;
      margin-bottom: 3rem;
    }
  }
}

.home-wrapper {
  overflow: hidden;
}

.home-brands {
  text-align: center;
  img {
    vertical-align: middle;
    max-width: 20%;
    height: auto;
    padding: 2%;
  }
}


/*  HOMEPAGE + PRODUCT PAGE*/

.home_gif {
  @include transition(all, .3s, ease-in-out);
  vertical-align: top;
  margin-bottom: 10px;
  display: inline-block;
  max-width: 24.5%;
  height: auto;
  padding: 0 1.8% 0 1.8%;
  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    @include transition(.2s, ease-in-out, all);
    margin-bottom: 10px;
  }
}

.home_prod {
  max-width: 24.5%;
  height: auto;
  padding: 0 1.8% 0 1.8%;
  display: inline-block;
  vertical-align: top;
  margin: 5px 0 0 0;
  text-align: left;
}

.prod {
  width: 24.5%;
  padding: 0 1.8% 0 1.8%;
  vertical-align: top;
  margin: 20px 0;
  display: inline-block;
}

.prod_thumb {
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include transition(all, .3s, ease-in-out);
  border-top: 3px solid $orange;
  vertical-align: top;
  margin-bottom: 10px;
  img {
    opacity: 0;
    width: 100%;
    height: auto;
    vertical-align: top;
    @include transition(.2s, ease-in-out, all);
  }
  &:hover {
    background-position-x: left;
    img {
      filter: alpha(opacity=1);
      opacity: 1;
    }
  }
  &.prod_small {
    filter: blur(3px);
    transform: scale(.9);
  }
}


.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.prodpage_images,
.prodpage_text {
  vertical-align: top;
  width: 50%;
  height: auto;
  display: inline-block;
  padding: 50px 0 0 0;
  margin-top: 70px;
}

.prodpage_images {
  padding: 0 5% 0 2.5%;
}

.prodpage_text {
  padding: 0 2.5% 0 5%;
}

.prodpage_text h2 {
  margin-bottom: 10px;
}

.product-image {
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
}

.prodpage_tag {
  font-size: 17px;
  font-size: 1.7rem;
  background-color: $white;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  padding: 9px 10px;
  margin: 0 5px 10px 0;
  color: $dark;
  border-bottom: 2px solid #c9c9c9;
  @include transition(.1s, ease-in-out, all);
}

.prodpage_tag:hover {
  color: $orange;
  transform: scale(.99);
  border-bottom: 0px solid #c9c9c9;
  margin-bottom: 12px;
}




/* ABOUT US PAGE */

.aboutus-nav,
.aboutus-content {
  display: inline-block;
  vertical-align: top;
}

.aboutus-image-cont {
  width: 100%;
  height: 250px;
  overflow: hidden;
  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
}

.aboutus-nav {
  width: 25%;
  padding: 0 4% 0 2%;
  font-size: 18px;
  font-size: 1.8rem;
  .parent {
    margin: 5px;
  }
  .nested {
    font-size: 16px;
    font-size: 1.6rem;
    margin: 5px 0 0 25px;
  }
  .nested:before {
    content: "\b7\a0";
    display: inline-block;
  }
  h3 {
    line-height: 1.4;
  }
}

.aboutus-content {
  width: 70%;
  padding: 0 2% 0 4%;
  h2 {
    margin-bottom: 10px;
    margin-bottom: 1rem;
  }
  p {
    line-height: 1.5;
    margin-bottom: 10px;
    margin-bottom: 1rem;
  }
  li {
    font-size: 15px;
    font-size: 1.5rem;
    font-family: $light-ff;
    line-height: 1.4;
    margin: 5px 0 5px 30px;
    list-style-type: disc;
  }
  blockquote {
    margin: 20px 50px;
    border-left: 5px solid $tan;
    padding: 0 10px;
    strong {
      font-family: $reg-ff;
      color: $gray;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: .02em;
      display: block;
      line-height: 2;
    }
  }
}

.about-left {
  width: 100%;
  img {
    max-width: 30%;
    height: auto;
    display: inline-block;
    vertical-align: top;
  }
  p {
    width: 70%;
    display: inline-block;
    vertical-align: top;
    padding: 5px 2%;
  }
}

.about-2col {
  width: 49%;
  display: inline-block;
  padding-right: 4%;
  margin: 5px auto 30px auto;
  vertical-align: top;
  img {
    max-width: 100%;
    height: auto;
  }
}

.prodpage_text {
  li {
    font-size: 15px;
    font-size: 1.5rem;
    font-family: $light-ff;
    line-height: 1.4;
    margin: 5px 0 5px 30px;
    list-style-type: disc;
  }
  blockquote {
    padding: 0 50px 0 30px;
  }
}

.product-page {
  padding-bottom: 5rem;
}
