/* footer */

footer {
    width: 100%;
    border-top: 3px solid $orange;
    color: $white;
    background-color: $dark;
    padding: 20px 6.2% 10px 6.2%;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.6;
    p {
        margin-bottom: 5px;
        color: $white;
    }
    a {
        color: $white;
    }
    a:hover {
        color: $orange;
    }
    strong {
        font-size: 18px;
        font-size: 1.8rem;
    }
    .footerright {
        width: 25%;
        display: inline-block;
        text-align: right;
        float: right;
        color: $light;
        margin-bottom: 30px;
    }
    .footerleft {
        width: 65%;
        display: inline-block;
        margin-bottom: 30px;
    }
    .stateface {
        color: $orange;
    }
    .width-80 {
        width: 79%;
        padding-right: 2%;
        display: inline-block;
        vertical-align: top;
    }
    .width-50 {
        width: 49%;
        padding-right: 2%;
        display: inline-block;
        vertical-align: top;
    }
    .width-20 {
        width: 19%;
        padding-right: 2%;
        display: inline-block;
        vertical-align: top;
    }
    .width-16 {
        width: 15%;
        padding-right: 1.67%;
        display: inline-block;
        vertical-align: top;
    }
    .a-right {
        border-left: 1px solid #453a32;
        float: right;
    }
    .a-right p {
        font-size: 16px;
        font-size: 1.6rem;
        text-align: right;
    }
    p {
        margin-bottom: 5px;
        color: #FFF;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 160%;
    }
    a {
        color: #FFF;
    }
    a:hover {
        color: $orange;
    }
    strong {
        font-family: 'prox-semi', sans-serif;
        font-size: 15px;
        font-size: 1.5rem;
    }
    small {
        font-size: 12px;
        font-size: 1.2rem;
    }
}

.footer-hr {
    height: 1px;
    background-color: #453a32;
    width: 100%;
    margin: 20px 0;
}

.footer-gc {
    border-top: 3px solid $blue;
}

.footer-gc a:hover {
    color: $blue;
}

.footer-gc .stateface {
    color: $blue;
}
