.contact-open {
  overflow: hidden;
}

.contact-cont {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.contact-cont-white {
  background-color: rgba(255, 255, 255, .7);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
}

.contact {
  background-color: $light;
  border-top: 4px solid $orange;
  width: 70%;
  height: 80%;
  max-width: 1300px;
  max-height: 1000px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  padding: 1%;
}

#contactForm {
  display: block;
  height: 100%;
  z-index: 999;
}

.contact-info div {
  width: 46%;
  display: inline-block;
  vertical-align: top;
  padding: 0 1%;
  z-index: 999;
}

#closeContact {
  font-size: 16px;
  font-size: 1.6rem;
  margin: auto;
  display: block;
  float: right;
  background-color: $white;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 10px 9px 9px;
  color: $dark;
  border-bottom: 2px solid #c9c9c9;
  @include transition(.2s, ease-in-out, all);
  z-index: 999;
}

.contact-request,
.contact-info {
  width: 85%;
  margin: 10px auto;
  text-align: center;
  max-width: 1200px;
  h2 {
    margin: 10px auto;
  }
}

.contact-request {
  height: 47%;
}

#bold-ff {
  font-family: $bold-ff;
}

.contact-float input:focus+label {
  color: $orange;
}

.contact-float input[value]+label {
  color: red;
}

.contact-float input::-ms-clear {
  display: none;
}

.contact-float {
  position: relative;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 4.5rem;
  height: 4.5rem;
  margin: 0;
  padding: 0;
  width: 100%;
  @media (max-width: 500px){
    line-height: 2.7;
    height: 5.7rem;
  }
}

.contact-float input {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 4.5rem;
  height: 4.5rem;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: transparent;
  z-index: 2;
  border-bottom: 1px solid $light;
  text-indent: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  @media (max-width: 500px){
    font-size: 2.8rem;
    line-height: 1.7;
    height: 5.7rem;
  }
}

.contact-float:last-child input {
  border-bottom: none;
}

.contact-float label {
  display: block;
  position: absolute;
  left: 20px;
  font-size: 16px;
  z-index: 1;
  transform-origin: 0 0em;
  transition: transform 160ms, color 200ms;
  transform: scale(1, 1) rotateY(0);
  color: #999;
}

.contact-float-float label {
  transform: scale(0.55, 0.55) rotateY(0);
}

.contact-float-float input {
  padding-top: 20px;
}

.contact-float-float input:invalid {
  background: url(../img/invalid.png) no-repeat 98% center;
}

.contact-float-float input:valid {
  background: url(../img/valid.png) no-repeat 98% center;
}

.contact-button-cta {
  @include transition(.2s, ease-in-out, all);
  background-color: $orange;
  border-bottom: 3px solid #b73b26;
  color: $white;
  cursor: pointer;
  font-family: $reg-ff;
  font-size: 2.0rem;
  font-size: 20px;
  height: 42px;
  line-height: 125%;
  margin: 20px auto;
  overflow: hidden;
  padding: 7px 25px;
  text-align: center;
  width: 300px;
  a:hover,
  &:hover {
    color: $white;
    border-color: white;
    background-color: darken($orange,2%);
  }
}

.contact-button-cta-mobile {
  @include transition(.2s, ease-in-out, all);
  background-color: $orange;
  border-bottom: 3px solid #b73b26;
  color: $white;
  cursor: pointer;
  font-family: $reg-ff;
  font-size: 2.0rem;
  line-height: 1.4;
  margin: 20px auto;
  padding: 10px 15px;
  text-align: center;
  width: 100%;
  a:hover,
  &:hover {
    color: $white;
    border-color: white;
    background-color: darken($orange,2%);
  }
}

.invalid-css {
  @include animation(invalid-css, 500ms, ease-in-out, 1);
  @include transition(.2s, ease-in-out, all);
}

.valid-css {
  @include transition(.5s, ease-in-out, all);
  font-size: 0px;
  font-size: 0rem;
  .fa-check-circle {
    font-size: 30px;
    font-size: 3rem;
  }
}

.form-white-bg {
  display: inline-block;
  background: $white;
  width: 100%;
  margin: 10px auto;
  text-align: left;
  border-bottom: 2px solid #c9c9c9;
  @include transition(.1s, ease-in-out, all);
}

#contact-thankyou,
#contact-form {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
}

.contact-hide-form {
  -webkit-transform: translateX(-2000px);
  -ms-transform: translateX(-2000px);
  transform: translateX(-2000px);
  @include transition(.5s, ease-in-out, all);
}

.contact-hide-thankyou {
  -webkit-transform: translateX(2000px);
  -ms-transform: translateX(2000px);
  transform: translateX(2000px);
  @include transition(.5s, ease-in-out, all);
}

.contact-show-thankyou {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.contact-prod {
  max-width: 32%;
  height: auto;
  padding: 0 2% 0 2%;
  vertical-align: top;
  margin: 0px 0 0 0;
  display: block;
}

.overflow {
  overflow: hidden;
  position: relative;
  height: 100%;
  min-height: 380px;
}

#contactForm_mb {
  max-width: 60rem;
  margin: 2rem auto 0;
}
