
/* BUTTONS! */

.button-cta {
  color: $white;
  background-color: $orange;
  border-bottom: 3px solid #b73b26;
  font-size: 20px; font-size: 2.0rem;
  font-family: $reg-ff;
  line-height: 1.25;
  padding: 7px 25px;
  display: inline-block;
  margin: 20px auto;
  @include transition(.1s, ease-in-out, all);
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: none;
}

.button-cta:hover {
  transform: scale(.99);
  border-bottom: 0px solid #b73b26;
  margin-bottom: 23px;
}

.button-blue {
  color: $white;
  background-color: $blue;
  border-bottom: 3px solid $blue-dark;
  font-size: 20px; font-size: 2.0rem;
  font-family: $reg-ff;
  line-height: 1.25;
  padding: 7px 25px;
  display: inline-block;
  margin: 20px auto;
  @include transition(.2s, ease-in-out, all);
  cursor: pointer;
  letter-spacing: 1px;
}

.button-blue:hover {
  transform: scale(.99);
  border-bottom: 0px solid $blue-dark;
  margin-bottom: 23px;
}

.button-white-cont {
  font-size: 20px; font-size: 2.0rem;
  width: 80%;
  margin: 10px auto auto auto;
  text-align: center;
}

.button-white {
  -webkit-appearance: none;
  border: 0px solid white;
  font-size: 2rem;
  background-color: $white;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 15px 10px 15px;
  margin: 15px 7px 0 0;
  cursor: pointer;
  outline: none;
  display: inline-block;
  border-bottom: 2px solid #c9c9c9;
}

.button-white:hover {
  color: $orange;
  transform: scale(.99);
  border-bottom: 0px solid #c9c9c9;
  margin-bottom: 2px;
}
.button-outline-orange {
  line-height: 1.4;
  font-size: 17px; font-size: 1.7rem;
  border: none;
  color: $orange;
  cursor: pointer;
  padding: 7px 12px;
  display: inline-block;
  margin: 15px 0;
  position: relative;
  transition: all 0.3s;
  border: 3px solid $orange;
  color: $orange;
  overflow: hidden;
}

.button-outline-orange:after {
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.3s;
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background: $orange;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.button-outline-orange:hover,
.button-outline-orange:active {
  color: #ffffff;
}

.button-outline-orange:hover:after {
  height: 630%;
  opacity: 1;
}

.button-outline-orange:active:after {
  height: 720%;
  opacity: 1;
}

.button-outline-width {
  @include transition(all, .3s, ease-in-out);
  width: auto;
  min-width: 340px;
}
