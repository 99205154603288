/* start global CSS */

html {
	font-size: 62.5%;
	width: 100%;
}

body {
	height: 100%;
	font-family: $reg-ff;
	font-size: 14px; font-size: 1.4rem;
	background-color: $light;
	color: $dark;
	width: 100%;
}

.underline {
   text-decoration: underline!important;
}

/* classes - margin bottoms */

.mb-5 {
	margin: 0 0 5px 0;
}

.mb-10 {
	margin: 0 0 10px 0;
}

.mb-15 {
	margin: 0 0 15px 0;
}

.mb-20 {
	margin: 0 0 20px 0;
}

.mb-30 {
	margin: 0 0 30px 0;
}

/* classes - responsive br tags */

.phone_only {
	display: none;
}

.tab_only {
	display: none;
}

.desk_only {
	display: inline-block;
}

.desk_tab_only {
	display: inline-block;
}

.tab_phone_only {
	display: none;
}

/* hrs */

.hr-arrow {
	height: 1px;
	margin: -25px 0 25px 0;
}

.hr-arrow:after {
  content: url('../img/hr-arrow.png');
  text-align: center;
  position: relative;
  top: -20px;
  left: 50%;
  margin-left: -20px;
  z-index:95
}

/* layout - containers */

.main-cont {
	width: 100%;
	padding: 10px 3% 0;
}

.vid_cont {
	padding: 0 15%;
	margin: 90px auto 100px auto;
}


/* start project */

.start-project-cont {
	background-color: $white;
	width: 100%;
	text-align: center;
	padding: 70px 15%;
}


/* selection */

::selection {
	background: #2d7ba4; background:rgba(45,123,164,.9);
	color: white;
}

::-moz-selection {
	background: #2d7ba4; background:rgba(45,123,164,.9);
	color: white;
}

/* responsive video */

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	margin-bottom: 20px;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.learn-more-home {
  font-size: 1.25rem;
  font-family: $light-ff;
  line-height: 1.20;
  color: $orange;
  text-transform: uppercase;
  letter-spacing: .02em;
  padding: 0;
  margin: 0 0 .3rem;
}

h1.learn-more-home  {
  font-size: 1.6rem;
  font-family: $reg-ff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: .02em;
  padding: 0!important;
}
