@media screen and (max-width: 900px) {
  #dropdown,
  .white-button-container {
    margin-top: 20px;
    width: 75%;
  }
  .dropdown-wrap,
  .button-white {
    margin: 7px auto 0 auto;
    width: 95%;
    padding: 10px 35px 10px 20px;
  }
  .dropdown-wrap:hover,
  #viewall:hover,
  .button-white:hover {
    margin-bottom: 1px;
  }
  input[type=text],
  textarea {
    width: 95%;
  }
}

@media screen and (max-width: 704px) {
  html {
    font-size: 52%;
    width: 100%;
  }
  body {
    width: 100%;
  }
  .main-cont {
    width: 100%;
    padding: 0 2%;
  }
  .vid_cont {
    padding: 0 3% 0 3%;
    margin: 10px auto 10px auto;
  }
  .gc_cont {
    width: 100%;
    padding: 30px 0 0 0;
  }
  // responsive display / hide
  .phone_only {
    display: inline-block;
  }
  .tab_only {
    display: none;
  }
  .desk_only {
    display: none;
  }
  .desk_tab_only {
    display: none;
  }
  .tab_phone_only {
    display: inline-block;
  }
  // typography
  h1 {
    font-size: 30px;
    font-size: 3rem;
  }
  h6 {
    font-size: 20px;
    font-size: 2rem;
  }
  // buttons
  .button-white {
    margin: 10px auto;
    display: block;
    width: 95%;
  }
  .button-outline-orange {
    line-height: 1.4;
    font-size: 17px;
    font-size: 1.7rem;
  }
  .button-outline-width {
    width: 80%;
    min-width: 80%;
  }
  // homepage
  .home_text {
    padding: 40px 8% 60px 8%;
    h1 {
      margin: 15px 0;
    }
  }
  .home_text2 {
    padding-top: 180px
  }
  .home_text3 {
    padding-top: 40px
  }
  .home_prod {
    max-width: 80%;
    height: auto;
    padding: 0;
    margin: 10px 10%;
  }
  .home_gif {
    max-width: 80%;
    height: auto;
    padding: 0;
    margin: 10px 10%;
  }
  .home-cont {
    margin: 0;
    padding-top: 52px;
    padding-bottom: 30px;
  }
  .home-cont h1 {
    text-align: center;
  }
  .home-brands img {
    display: inline-block;
    vertical-align: middle;
    max-width: 49.5%;
    text-align: center;
    height: auto;
    padding: 5%;
  }
  .hr-arrow {
    margin: 0;
  }
  .hr-arrow:after {
    margin-left: -20px;
    top: -75px;
  }
  .menu-level-1 a { padding: 5px; }
  .menu-level-2 {
    font-size: 16px;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 0px;
    padding-right: 0;
    letter-spacing: 0px;
    a {
      padding: 20px 7px 15px 7px;
    }
    .darkbar-li-desk {
      display: none;
    }
    .darkbar-li-tab {
      display: inline-block;
    }
  }
  .menu-level-3 {
    font-size: 17px;
    font-size: 1.7rem;
    text-align: center;
    margin-bottom: 0px;
    padding-right: 0;
    letter-spacing: 0px;
    a {
      padding: 20px 7px 15px 7px;
    }
    .darkbar-li-desk {
      display: none;
    }
    .darkbar-li-tab {
      display: inline-block;
    }
    .dropdown {
      left: -50%;
    }
  }
  // PRODUCT PAGE
  .prodpage_text {
    padding: 0 5% 0 5%;
    float: none;
    h4 {
      text-align: center;
    }
  }
  .prodpage_images,
  .prodpage_text {
    width: 100%;
    padding: 10px 3%;
  }
  .back-button {
    margin: 20px 0 0 0;
    text-align: center;
    background-color: $white;
    border-bottom: 2px solid #c9c9c9;
    padding: 10px 5px;
  }
  // CONTACT PAGE
  .contact-float {
    line-height: 2.7;
    height: 5.7rem;
  }
  .contact-float input {
    font-size: 2.8rem;
    line-height: 1.7;
    height: 5.7rem;
  }
  #contact-form-mobile {
    position: static;
    display: inline-block;
    padding: 0 2%;
    width: 100%;
  }
  .contact-prod {
    max-width: 80%;
    height: auto;
    padding: 0 2% 0 2%;
    vertical-align: top;
    margin: auto;
    display: block;
  }
  .about-drop-cont {
    width: 95%;
    margin: 20px auto 20px auto;
  }
  /* _phone FOOTER*/
  .about-2col {
    width: 98%;
    margin: 5px auto 30px auto;
  }

  /* _phone SWIPER CSS*/
  .pagination {
    text-align: center;
    bottom: 5px;
    width: 100%;
    margin-top: 10px;
  }
  .swiper-pagination-switch {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #999;
    box-shadow: 0px 1px 2px #555 inset;
    margin: 0 3px;
    cursor: pointer;
  }
  .swiper-active-switch {
    background: #fff;
  }
  .swiper-container {
    background-color: white;
    width: 100%;
    height: 500px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;
    z-index: 1;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    transition-property: transform, left, top;
    transition-duration: 0s;
    transform: translate3d(0px, 0, 0);
    transition-timing-function: ease;
  }
  .swiper-slide {
    text-align: center;
    float: left;
    width: 100%;
    height: 500px;
    .vert-align {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    img {
      vertical-align: middle;
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
  footer {
    width: 100%;
    border-top: 3px solid $orange;
    color: $white;
    background-color: $dark;
    padding: 20px 6.2% 10px 6.2%;
    font-size: 14px;
    font-size: 1.4rem;
    p {
      font-size: 15px;
      font-size: 1.5rem;
      margin-bottom: 5px;
      color: $white;
    }
    a {
      color: $white;
    }
    a:hover {
      color: $orange;
    }
    strong {
      font-size: 16px;
      font-size: 1.6rem;
    }
    small {
      font-size: 14px;
      font-size: 1.4rem;
    }
    .footerright {
      width: 100%;
      text-align: left;
      float: none;
    }
    .footerleft {
      width: 100%;
    }
    .width-80 {
      width: 100%;
    }
    .width-20,
    .width-16 {
      width: 49%;
      margin-bottom: 10px;
    }
    .a-right {
      width: 100%;
      border-left: 0px solid #453a32;
    }
    .a-right p {
      text-align: left;
      float: none;
    }
    .footer-hr {
      margin: 8px 0 20px 0;
    }
  }
}
