.gc-cont {
	width: 100%;
	padding: 70px 5% 0 5%;
}

.gc_prod {
	width: 33%;
	height: auto;
	padding: 0 1.5% 0 1.5%;
	display: inline-block;
	vertical-align: top;
	margin: 5px 0 0 0;
}

//thumbnail
.gc_thumb {
	background-repeat: no-repeat;
	background-size: 101%;
	background-position-x: right;
	background-position-y: center;
	@include transition(all, .3s, ease-in-out);
	vertical-align: top;
	margin-bottom:10px;
	border-top: 3px solid $blue;

	img {
		filter: alpha(opacity=0);
		opacity: 0;
		width: 100%;
		height: auto;
		vertical-align: top;
		@include transition(.2s, ease-in-out, all);
	}
}

.gc_thumb:hover {
	background-position-x: left;

	img {
		filter: alpha(opacity=1);
		opacity: 1;
	}
}

.gc-prod-cont {
	width: 24.5%;
	padding: 0 1.2% 0 1.2%;
	vertical-align: top;
	display: inline-block;

	h2 {
		padding: 0;
	}

	p {
		font-size: 14px; font-size: 1.4rem;
		font-family: $reg-ff;
		line-height: 140%;
		color: #777;
		text-transform: none;
		margin: 0;
		padding: 0;
	}

}

.gc-title-cont {
	padding: 20px 3%;
}

.gc-prod-img {
	background-repeat: no-repeat;
	background-size: 101%;
	background-position-x: right;
	background-position-y: center;
	@include transition(all, .3s, ease-in-out);
	border-top: 3px solid $blue;
	vertical-align: top;
	margin-bottom:10px;

	img {
		filter: alpha(opacity=0);
		opacity: 0;
		width: 100%;
		height: auto;
		vertical-align: top;
		@include transition(.2s, ease-in-out, all);
	}
}

.gc-prod-img:hover {
	background-position-x: left;


	img {
	filter: alpha(opacity=1);
	opacity: 1;

	}
}

#videoContainer {
	position: relative;
	padding-bottom: 47%;
	height: 0;
	margin-bottom: 20px;
}
#videoContainer iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-playbutton {
	width: 50px!important;
	height: 50px!important;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	z-index: 2;
}

.video-placeholder {
	position: relative;
	z-index: 1;
}
