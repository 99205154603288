/* variables - color */
$thin-ff: 'prox-thin',sans-serif;
$thin-ital-ff: 'prox-thin-ital',sans-serif;
$light-ff: 'prox-light', sans-serif;
$light-ital-ff: 'prox-light-ital', sans-serif;
$reg-ff: 'prox-reg', sans-serif;
$reg-ital-ff: 'prox-reg-ital', sans-serif;
$bold-ff: 'prox-semi', sans-serif;
$bold-ital-ff: 'prox-semi-ital', sans-serif;
$boldbold-ff:'prox-bold', sans-serif;
$boldbold-ital-ff:'prox-bold-ital', sans-serif;

$script-bold-ital: 'libre-ital', serif;
$amp: 'amp', serif;

$orange-light: #FF4D00;

$orange: #E84600;
$dark: #31251C;
$darkdark: #261D16;
$tan: #DBD9D6;
$light: #F0F0F0;
$white: #FFF;
$gray: #777;
$gray-light: #939393;

$gc-blue: #21759C;

$blue-light: #009BDE;
$blue: #21759C;
$blue-dark: #185572;

$darktan: #CCC5BF;
$lighttan: #E0DAD5;

@mixin transition($transition-name, $transition-time, $method) {
  transition: $transition-name $transition-time $method;
}
@mixin animation($animation-name, $animation-time, $method, $inf) {
  animation: $animation-name $animation-time $method $inf;
}
@mixin anidelay($delay-time) {
  animation-delay: $delay-time;
}

@import '_00_reset';
@import '_10_fonts';
@import '_20_globals';
@import '_21_type';
@import '_22_buttons';
@import '_23_nav';
@import '_30_pagestyles';
@import '_31_ourwork';
@import '_32_footer';
@import '_40_dropdowns';

@import '_50_contact';
@import '_60_giftcards';
@import '_70_largedesk';
@import '_80_tablet';
@import '_90_phone';

@import '_99_animations';
@import '_99_font-awesome';
@import '_99_flexslider';



.catalog-container {
  padding-top: 50px;
  text-align: center;
  height: 100%;
  .pdf-container {
    height: 80%;
  }
  .pdf-frame {
    width: 100%;
    height: 100%;
  }
}
