
/* typography */

a {
  color: inherit;
  cusor: pointer;
  @include transition(.1s, ease-in-out, all);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  color: $orange;
  text-decoration: none;
}

h1 {
  font-size: 40px;
  font-size: 4rem;
  font-family: $light-ff;
  line-height: 1.35;
}

.video_section h1 {
  text-align: right;
}

h2 {
  font-size: 24px; font-size: 2.4rem;
  font-family: $reg-ff;
  line-height: 1.20;
  color: $dark;
}

h2 a:hover, a h2:hover {
  color: $orange;
}

h3 {
  font-size: 20px; font-size: 2.0rem;
  font-family: $reg-ff;
  line-height: 1.20;
  color: $dark;
}

h4 { //subheads of products - gray
  font-size: 16px; font-size: 1.6rem;
  font-family: $reg-ff;
  line-height: 1.20;
  color: $gray;
  text-transform: uppercase;
  letter-spacing: .02em;
}

h5 {
  font-size: 16px; font-size: 1.6rem;
  font-family: $reg-ff;
  line-height: 1.20;
  color: $orange;
  text-transform: uppercase;
  letter-spacing: .02em;

}

h5 a:hover, a h5:hover {
  color: $orange-light;
}

h6 {
  font-size: 23px;
  font-size: 2.3rem;
  font-family: $light-ff;
  line-height: 1.35;
}

p {
  font-size: 16px; font-size: 1.6rem;
  font-family: $light-ff;
  line-height: 1.45;
  word-break: break-word;
  color: $dark;
  margin: 0 0 10px 0; margin: 0 0 1rem 0;

  a {
    font-family: $reg-ff;
    color: $blue;
    letter-spacing: .2px;
  }

  strong {
    font-family: $reg-ff;
  }

  em {
    font-family: $light-ital-ff;
  }

  strong em, em strong {
    font-family: $reg-ital-ff;
  }

  a:hover {
  color: $blue-light;
  }
}

li {
  a {
    font-family: $reg-ff;
    color: $blue;
    letter-spacing: .2px;
  }
}

ul {
  margin: 0 0 15px 0; margin: 0 0 1.5rem 0;
}
